/**
 *
 * Basic homepage after logged in
 *
 */

//external libraries
import React from "react";

const HomePage = props => {
  const renderHome = () => {
    return "you're home!";
  };

  return renderHome();
};

export default HomePage;
