import React from "react";
import { Switch, Route } from "react-router-dom";

import PublicHomePage from "./public_pages/PublicHomePage";
import PublicAboutPage from "./public_pages/PublicAboutPage.js";
import PublicLoggedOutPage from "./public_pages/PublicLoggedOutPage.js";

import HomePage from "./HomePage";
import ProfilePage from "./ProfilePage";
import ExternalApi from "./ExternalApiPage";
import LandingPage from "./LandingPage";

import ProductPage from "./ProductPage";

import { PrivateRoute } from "./components";

import Generic404Page from "./layout/Generic404Page";

const Router = () => (
  <Switch>
    <Route exact path="/" component={PublicHomePage} />

    {/**private routes**/}
    <PrivateRoute path="/products" component={ProductPage} />

    <PrivateRoute path="/home" component={HomePage} />
    <PrivateRoute path="/profile" component={ProfilePage} />
    <PrivateRoute path="/external-api" component={ExternalApi} />

    <PrivateRoute path="/landing" component={LandingPage} />

    {/**public routes**/}
    <Route path="/about" component={PublicAboutPage} />
    <Route path="/logged-out" component={PublicLoggedOutPage} />

    <Route path="/" component={Generic404Page} />
  </Switch>
);

export default Router;
