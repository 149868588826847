/**
 * Homepage for the public
 */

import React from "react";

const PublicHomePage = () => {
  const renderPage = () => {
    return <div>This is the public home. Selamat datang ke Demapra</div>;
  };

  return renderPage();
};

export default PublicHomePage;
