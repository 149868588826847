import styled from "styled-components";

const Site = styled.div`
  background-color: #fff5f5;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
`;

export default Site;
