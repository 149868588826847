/*
 * Component for when the user is logged in.
 * Will be displayed on the left side of the main
 * feed space.
 */

import React, { Fragment } from "react";

import { MdList } from "react-icons/md";
import { IoMdLogOut } from "react-icons/io";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Avatar,
  Box,
  Flex,
  Link,
  Text
} from "@chakra-ui/react";
import { Link as rrDomLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import urls from "urls";

const NavLinkItem = ({
  linkAs,
  linkTo,
  iconSize,
  iconName,
  caption,
  onClick
}) => {
  return (
    <Link
      _hover={{ backgroundColor: "gray.200" }}
      borderRadius="20px"
      p={2}
      as={linkAs}
      to={linkTo}
      mt={3}
      onClick={onClick}
    >
      <Flex align="center">
        <Box fontSize={iconSize} as={iconName} color="blue.500" />
        <Flex ml={2} display={{ base: "none", sm: "none", md: "flex" }}>
          <b>{caption}</b>
        </Flex>
      </Flex>
    </Link>
  );
};

const LoggedInBar = props => {
  const { logout } = useAuth0();
  const internalUser = props.internalUser;

  if (internalUser && internalUser.username) {
    return (
      <Fragment>
        <Link
          _hover={{ backgroundColor: "gray.200" }}
          borderRadius="20px"
          p={2}
          mt={4}
          as={rrDomLink}
          to="/profile"
        >
          <Flex align="center">
            <Avatar
              size="md"
              name={internalUser.username}
              src={internalUser.profile_pic_url}
            />
            <Text ml={2} display={{ base: "none", sm: "none", md: "flex" }}>
              <b>{internalUser.username}</b>
            </Text>
          </Flex>
        </Link>

        <Accordion allowToggle>
          <AccordionItem>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                Produk
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Flex direction="column">
                <NavLinkItem
                  linkAs={rrDomLink}
                  linkTo="/products"
                  iconSize="35px"
                  iconName={MdList}
                  caption="Daftar Produk"
                />
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <NavLinkItem
          linkAs={rrDomLink}
          linkTo="#logout"
          iconSize="35px"
          iconName={IoMdLogOut}
          onClick={() => logout({ returnTo: urls.FRONTEND.LOGGED_OUT })}
          caption="Logout"
        />
      </Fragment>
    );
  }
  return "";
};

export { LoggedInBar };
