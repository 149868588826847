import React from 'react';

const Generic404Page = () => (
 	<div className="columns">
		<div className="column is-half is-offset-one-quarter">
			<h1 className="title is-1">404 Page Not Found</h1>
			<p>Our smart monkeys could not find the page you're looking for... Sorry :(</p>
		</div>
	</div>

);

export default Generic404Page
