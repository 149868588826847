import React from "react";
import { Box, Heading, Flex, Link, Button } from "@chakra-ui/react";

import { useAuth0 } from "@auth0/auth0-react";
import { Link as rrDomLink } from "react-router-dom";
import urls from "urls";

const MenuItems = ({ linkUrl, children }) => (
  <Link
    mt={{ base: 4, md: 0 }}
    mr={6}
    display="block"
    as={rrDomLink}
    to={linkUrl}
    _hover={{ color: "gray.200" }}
  >
    {children}
  </Link>
);

const Header = props => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const renderRegLoginButton = isLoading => {
    if (isLoading || isAuthenticated) {
      return;
    }
    if (!isAuthenticated) {
      return (
        <Button
          bg="transparent"
          colorScheme="blue"
          border="1px"
          onClick={() =>
            loginWithRedirect({
              redirectUri: urls.FRONTEND.LANDING
            })
          }
        >
          Login/Daftar
        </Button>
      );
    }
  };

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="0.5rem 1.5rem 0.5rem 1.5rem"
      bg="blue.400"
      color="white"
      {...props}
    >
      <Flex mr={7}>
        <Heading as="h1" size="md">
          <Link to="/" as={rrDomLink} _hover={{ color: "gray.200" }}>
            Demapra
          </Link>
        </Heading>
      </Flex>

      <Box display={{ sm: "block", md: "none" }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Flex
        display={{ sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        flexGrow={1}
      >
        <MenuItems linkUrl="/about">About</MenuItems>
      </Flex>

      <Box
        display={{ sm: show ? "block" : "none", md: "block" }}
        mt={{ base: 4, md: 0 }}
      >
        {renderRegLoginButton(isLoading)}
      </Box>
    </Flex>
  );
};

export default Header;
