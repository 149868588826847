import styled from "styled-components";

//default color is purple.400
const Content = styled("div")`
  width: 90%;
  margin: 15px auto 0 auto;
  display: flex;
  justify-content: center;
`;

export default Content;
