//User Profile component

import React, { useReducer } from "react";
import superagent from "superagent";
import { Formik, Form, Field } from "formik";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Input,
  Spinner,
  useToast
} from "@chakra-ui/react";

import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "UserContext";
import urls from "urls";

//****************reducers**********************
const pageInitState = {
  uploadInProgress: false
};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_UPLOAD_PROGRESS":
      return {
        ...state,
        uploadInProgress: !state.uploadInProgress
      };
    default:
      return state;
  }
};

const ProfilePage = props => {
  const [pageState, pageDispatch] = useReducer(pageReducer, pageInitState);

  const { isLoading, user, getAccessTokenSilently } = useAuth0();
  const { internalUser, setInternalUser } = useUserContext();
  const toast = useToast();

  const renderProfileForm = () => {
    if (!internalUser) {
      return "";
    }
    const initialUsername = (" " + internalUser.username).slice(1);

    const checkUsername = async value => {
      const token = await getAccessTokenSilently();
      return superagent
        .get(urls.API.USERS.CHECK_USERNAME + "/" + value)
        .set("Authorization", `Bearer ${token}`);
    };

    const validateUsername = async value => {
      let error;
      if (value) {
        const checkUsernameRes = await checkUsername(value);
        const usernameExists = checkUsernameRes.body.found;
        if (value.length < 2 || value.length > 15) {
          error = "Username harus antara 2-15 karakter";
        } else if (!/^[A-Z0-9._%+-]{2,15}$/i.test(value)) {
          error = "Username hanya boleh berisi a-z, 0-9, . _ % + -";
        } else if (initialUsername !== value && usernameExists) {
          error = "Username sudah ada";
        }
        return error;
      }
      error = "Username tidak boleh kosong";
      return error;
    };

    const handleFormikSubmit = (values, token) => {
      // email is unchangeable
      const newData = {
        full_name: values.full_name,
        username: values.username
      };

      superagent
        .put(urls.API.USERS.BASIC + "/" + internalUser.id)
        .set("Authorization", `Bearer ${token}`)
        .send(newData)
        .then(res => {
          setInternalUser(res.body);

          toast({
            title: "Success!",
            description: "Profil sudah di-update",
            status: "success",
            duration: 5000,
            isClosable: true
          });
        })
        .catch(err => {
          toast({
            title: "Gagal!",
            description: "Profil gagal update.",
            status: "error",
            duration: 5000,
            isClosable: true
          });

          console.log(err);
        });
    };

    if (internalUser.username) {
      return (
        <Formik
          initialValues={{
            full_name: internalUser.full_name,
            username: internalUser.username,
            email: user.email
          }}
          onSubmit={async values => {
            const token = await getAccessTokenSilently();
            pageDispatch({ type: "TOGGLE_UPLOAD_PROGRESS" });
            handleFormikSubmit(values, token);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Field name="email">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.email && form.touched.email}
                  >
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      {...field}
                      id="email"
                      placeholder="Email Anda..."
                      disabled
                    />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="username" validate={validateUsername}>
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.username && form.touched.username}
                  >
                    <FormLabel htmlFor="username">Username</FormLabel>
                    <Input
                      {...field}
                      id="username"
                      placeholder="Username Anda..."
                    />
                    <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="full_name">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.full_name && form.touched.fullName}
                  >
                    <FormLabel htmlFor="full_name">Nama Lengkap</FormLabel>
                    <Input
                      {...field}
                      id="full_name"
                      placeholder="Nama Anda..."
                    />
                    <FormErrorMessage>{form.errors.full_name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                isLoading={pageState.uploadInProgress}
                type="submit"
                mt={2}
                colorScheme="purple"
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
      );
    }
    return <Spinner size="lg" color="purple.400" />;
  };

  const renderPage = () => {
    if (isLoading || !user || !internalUser.username) {
      return <Flex>Loading...</Flex>;
    }
    return (
      <Flex direction="column" alignSelf="flex-start">
        <Heading as="h1" size="2xl">
          Profile
        </Heading>

        {renderProfileForm()}
      </Flex>
    );
  };

  return renderPage();
};

export default ProfilePage;
