/**
 * Landing page after login / registration
 *
 * Will verify the data with our internal server,
 * if there's an error, logout in 5 secs
 */

import React, { useEffect } from "react";

import superagent from "superagent";

import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../UserContext";
import urls from "../urls";

import {
  Alert,
  AlertTitle,
  AlertDescription,
  Box,
  CircularProgress,
  useToast
} from "@chakra-ui/react";

const LandingPage = () => {
  /*
   * MAIN LANDING COMPONENT
   */
  const history = useHistory();
  const toast = useToast();
  const { logout, user, getAccessTokenSilently } = useAuth0();
  const { setInternalUser } = useUserContext();

  const userDataJson = {
    email: user.email,
    auth0_id: user.sub
  };

  useEffect(
    () => {
      //STEP: create or verify user
      const createOrVerify = async () => {
        try {
          const token = await getAccessTokenSilently();

          const res = await superagent
            .post(urls.API.USERS.CREATE_OR_VERIFY)
            .set("Authorization", `Bearer ${token}`)
            .send(userDataJson);

          setInternalUser(res.body.user);

          if (res.body["new"]) {
            toast({
              title: "Registrasi Sukses.",
              description: "Sukses! Ganti username dan lengkapi profil Anda.",
              status: "success",
              duration: 5000,
              isClosable: true
            });
          }
          history.push("/home");
          // EVERYONE IS REDIRECTED TO LOGGED-IN HOME
        } catch (err) {
          //show on screen, auto logout in 5 secs
          console.error(err);
          toast({
            title: "Registrasi Gagal.",
            description: "Gagal...kontak admin untuk info lebih lanjut",
            status: "error",
            duration: 5000,
            isClosable: true
          });

          logout({ returnTo: urls.FRONTEND.LOGGED_OUT });
        }
      };

      createOrVerify();
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const alertProgressContent = (
    <Alert
      borderRadius="15px"
      status="success"
      variant="subtle"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
    >
      <CircularProgress mr={0} isIndeterminate></CircularProgress>
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Login berhasil!
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        Terima kasih telah login. Mohon menunggu selagi kami lakukan verifikasi
        data
      </AlertDescription>
    </Alert>
  );

  return <Box>{alertProgressContent}</Box>;
};

export default LandingPage;
