import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import { Flex, Spinner } from "@chakra-ui/react";

import { LoggedInBar } from "./components";
import Site from "./layout/Site";
import Header from "./layout/Header";
import Content from "./layout/Content";
//import Footer from "./layout/Footer";
import Router from "./Router";

import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../UserContext";

const renderLoggedInBar = internalUser => {
  return (
    <>
      {internalUser && internalUser.username ? (
        <Flex
          as="nav"
          ml="0.7rem"
          flex={1}
          position={{ base: "fixed", sm: "fixed", md: "relative" }}
          left="0"
          top={{ base: "3.5rem", sm: "3.5rem", md: "0" }}
          direction="column"
          zIndex="5"
        >
          <LoggedInBar internalUser={internalUser} />
        </Flex>
      ) : (
        ""
      )}
    </>
  );
};

/**
 * Layout Functional Component
 */
const routerStyleLoggedIn = { base: "3rem", sm: "3rem", md: "1rem", lg: 0 };
const renderMainContent = isLoading => {
  if (isLoading) {
    return <Spinner mt="3rem" size="xl" color="teal.400" />;
  }

  return (
    <>
      <Flex
        ml={isLoading ? routerStyleLoggedIn : 3}
        p={3}
        height="100%"
        flex={5}
      >
        <Router />
      </Flex>
    </>
  );
};

const Layout = ({ children }) => {
  const { isLoading } = useAuth0();
  const { internalUser } = useUserContext();
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (internalUser && internalUser.username) {
      setLoggedIn(true);
    }
  }, [internalUser]);

  return (
    <Site>
      <Helmet
        title="Demapra"
        meta={[
          { name: "description", content: "Demapra" },
          { name: "keywords", content: "demapra, bot" }
        ]}
        script={[]}
        link={[]}
      />
      <Header />
      <Content>
        {isLoggedIn && renderLoggedInBar(internalUser)}
        {renderMainContent(isLoading)}
      </Content>
    </Site>
  );
};

Layout.propTypes = {
  children: PropTypes.func
};

export default Layout;
