/*
 * list of urls used for api etc
 */

//const BASIC_URL_API = "http://localhost:8000/api";
//const BASIC_FRONTEND = "http://localhost:3000";
const BASIC_URL_API = "https://demapra.com/api";
const BASIC_FRONTEND = "https://demapra.com";

const urls = {
  BASIC: BASIC_URL_API,
  FRONTEND: {
    BASIC: BASIC_FRONTEND,
    LANDING: BASIC_FRONTEND + "/landing",
    LOGGED_OUT: BASIC_FRONTEND + "/logged-out"
  },
  API: {
    USERS: {
      BASIC: BASIC_URL_API + "/users",
      CHECK_USERNAME: BASIC_URL_API + "/users/check-username",
      CREATE_OR_VERIFY: BASIC_URL_API + "/users/create-or-verify",
      GET_USER_BY_AUTH_ID: BASIC_URL_API + "/users/get-user-by-auth-id"
    },
    PRODUCTS: {
      BASIC: BASIC_URL_API + "/products",
      GET_PRODUCTS_FR_KEYWORDS: BASIC_URL_API + "/products/get-from-keywords",
      UPLOAD_PRODUCT_STOCKS: BASIC_URL_API + "/products/upload-product-stocks"
    }
  }
};

export default urls;
