/**
 * Page for when the public is logged out,
 * either because:
 * 		- not authorized, token
 * 		- failed log in
 */

import React from "react";

const PublicLoggedOutPage = () => {
  const renderPage = () => {
    return <div>You have been logged out!!! Unauthorized login.</div>;
  };

  return renderPage();
};

export default PublicLoggedOutPage;
