/**
 * Loading component
 */

import React from "react";

const Loading = () => {
  const renderComp = () => {
    return <div>Loading...Harap bersabar yaa...</div>;
  };

  return renderComp();
};

export { Loading };
