import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";
import { UserProvider } from "./UserContext";
import { auth_config } from "./config.js";
import history from "./utils/history";

ReactDOM.render(
  <Auth0Provider
    domain={auth_config.domain}
    clientId={auth_config.clientId}
    redirectUri={window.location.origin}
    audience={auth_config.auth0_mgmt_audience}
    scope={auth_config.scope}
  >
    <UserProvider>
      <Router history={history}>
        <App />
      </Router>
    </UserProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
