// UserContext React Wrapper
//

import React, { useEffect, useState, useContext } from "react";
import superagent from "superagent";

import { useAuth0 } from "@auth0/auth0-react";

import urls from "./urls";

export const UserContext = React.createContext();
export const useUserContext = () => useContext(UserContext);
export const UserProvider = ({ children, initOptions }) => {
  const [internalUser, setInternalUser] = useState({});
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  const getSetInternalUser = async user_id => {
    try {
      const token = await getAccessTokenSilently();

      const res = await superagent
        .get(urls.API.USERS.GET_USER_BY_AUTH_ID + "/" + user_id)
        .set("Authorization", `Bearer ${token}`);
      const data = res.body;

      setInternalUser(data);
    } catch (err) {
      console.log(err);
      logout({ returnTo: urls.FRONTEND.LOGGED_OUT });
    }
  };

  useEffect(
    () => {
      const url = window.location.href;
      if (isAuthenticated && user && url != urls.FRONTEND.LANDING) {
        getSetInternalUser(user.sub);
      }
    }, //eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, user]
  );

  return (
    <UserContext.Provider
      value={{
        internalUser,
        getSetInternalUser,
        setInternalUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
