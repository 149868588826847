import React from "react";
import Layout from "./app/Layout";

import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./customTheme.js";

function App() {
  return (
    <ChakraProvider theme={customTheme}>
      <Layout />
    </ChakraProvider>
  );
}

export default App;
