/**
 *
 * Product page
 *
 * Features:
 * 	- list of products
 * 	- search product,
 *  - upload products
 *
 */

//external libraries
import React, { useReducer } from "react";
import superagent from "superagent";
import { useAuth0 } from "@auth0/auth0-react";
import { useDropzone } from "react-dropzone";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Divider,
  Heading,
  InputGroup,
  InputLeftElement,
  Input,
  ListItem,
  Stack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  UnorderedList,
  useToast
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

import urls from "../urls";

const stocks_to_str = (stocks, outside_main_qty) => {
  if (stocks.length > 0) {
    return (
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Gudang</Th>
            <Th>Jumlah</Th>
          </Tr>
        </Thead>
        <Tbody>
          {stocks.map((row, idx) => {
            return (
              <Tr key={"TrbodyStock" + idx}>
                <Td>{row.storage_name}</Td>
                <Td>{row.qty}</Td>
              </Tr>
            );
          })}
          {outside_main_qty > 0 ? (
            <Tr key="TrbodyStockOut">
              <Td>Gudang lain / proyek</Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
    );
  }

  return "";
};

const product_to_str = ({
  stock_code,
  description,
  total_qty,
  qty_stock_outside_main_storages,
  updated_at,
  stocks
}) => {
  const dtUtc = new Date(updated_at);
  return (
    <Flex direction="column">
      <Text>
        {stock_code} : {description}
      </Text>
      <Text>Total qty : {total_qty}</Text>
      {stocks_to_str(stocks, qty_stock_outside_main_storages)}
      <Text fontSize="xs">Updated : {dtUtc.toLocaleString("id-ID")}</Text>
    </Flex>
  );
};

const successAlertMsg = data => {
  const { filename, success_count, overwrite_count } = data;
  return (
    <Stack spacing={2}>
      <Text>Sukses upload stok dari file {filename}!</Text>
      {success_count > 0 ? <Text>Stok terupload: {success_count}</Text> : ""}
      {overwrite_count > 0 ? (
        <Text>Stok terupdate: {overwrite_count}</Text>
      ) : (
        ""
      )}
    </Stack>
  );
};

const failedAlertMsg = data => {
  const {
    filename,
    success_count,
    overwrite_count,
    fail_overwrite_count,
    fail_count,
    failed_overwrite_rows,
    failed_rows
  } = data;

  const frList = failed_rows.slice(0, 5);
  const frOwList = failed_overwrite_rows.slice(0, 5);
  return (
    <Stack spacing={2} p={3}>
      <Text>Error upload file ${filename}!</Text>
      <Text>Barang baru: {success_count}</Text>
      <Text>Barang ter-update: {overwrite_count}</Text>
      <Text align="left">Gagal membuat barang ({fail_count} produk):</Text>
      <UnorderedList align="left">
        {frList.map((row, idx) => {
          return (
            <ListItem key={"failedRow" + idx}>
              {row.stock_code} : {row.description}
            </ListItem>
          );
        })}
        {failed_rows.length > 5 ? <ListItem>...</ListItem> : ""}
      </UnorderedList>
      <Text align="left">Gagal update ({fail_overwrite_count} produk):</Text>
      <UnorderedList align="left">
        {frOwList.map((row, idx) => {
          return (
            <ListItem key={"failedRow" + idx}>
              {row.stock_code} : {row.description}
            </ListItem>
          );
        })}
        {failed_overwrite_rows.length > 5 ? <ListItem>...</ListItem> : ""}
      </UnorderedList>
    </Stack>
  );
};

//***************reducers******************

const pageInitState = {
  searchQuery: "",
  searchResults: [],
  uploadText:
    "Drag n drop 1 file stok .xls atau .xlsx di sini, atau click untuk pilih file",
  showAlert: false,
  enableUpload: false,
  isUploading: false
};

const pageReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_ALERT":
      return {
        ...state,
        showAlert: !state.showAlert
      };
    case "SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.payload
      };
    case "SEARCH_SUCCESSFUL":
      return {
        ...state,
        searchResults: action.payload
      };
    case "FILE_PICK_SUCCESSFUL":
      return {
        ...state,
        showAlert: false,
        uploadText: action.payload,
        enableUpload: true,
        isUploading: false
      };
    case "START_UPLOAD":
      return {
        ...state,
        isUploading: true
      };
    case "UPLOAD_FAILED":
      return {
        ...state,
        alertMsg: action.payload.systemFault
          ? "Upload gagal karena kesalahan sistem. Kontak admin."
          : failedAlertMsg(action.payload),
        uploadText:
          "Drag n drop 1 file stok .xls atau .xlsx di sini, atau klik untuk pilih file",
        alertStatus: "error",
        showAlert: true,
        enableUpload: false,
        isUploading: false
      };
    case "UPLOAD_SUCCESSFUL":
      return {
        ...state,
        alertMsg: successAlertMsg(action.payload),
        uploadText:
          "Drag n drop 1 file stok .xls atau .xlsx di sini, atau klik untuk pilih file",
        showAlert: true,
        alertStatus: "success",
        enableUpload: false,
        isUploading: false
      };
    default:
      return state;
  }
};

const dropzoneStyle = {
  borderStyle: "dashed",
  border: "1px dashed",
  backgroundColor: "#fafafa",
  flex: 1,
  padding: 5
};

const ProductPage = props => {
  /*
   * Product page
   */
  const [pageState, pageDispatch] = useReducer(pageReducer, pageInitState);
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".xls, .xlsx, application/vnd.ms-excel",
    maxFiles: 1,
    onDropAccepted: files => {
      pageDispatch({ type: "FILE_PICK_SUCCESSFUL", payload: files[0].name });
    },
    onDropRejected: files => {
      toast({
        title: "Error File Upload",
        description:
          "Pastikan file dalam format .xls atau .xlsx., dan hanya 1 file.",
        status: "error",
        duration: 5000,
        isClosable: true
      });
    }
  });

  const handleChange = async event => {
    const q = event.target.value;
    pageDispatch({ type: "SEARCH_QUERY", payload: q });
    // search
    try {
      const token = await getAccessTokenSilently();
      superagent
        .get(`${urls.API.PRODUCTS.GET_PRODUCTS_FR_KEYWORDS}?q=${q}`)
        .set("Authorization", `Bearer ${token}`)
        .then(res => {
          pageDispatch({ type: "SEARCH_SUCCESSFUL", payload: res.body });
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUpload = async files => {
    if (files.length == 0) {
      return;
    }

    // UPLOAD TO API HERE
    const formData = new FormData();
    formData.append("file", files[0]);

    pageDispatch({ type: "START_UPLOAD" });

    const token = await getAccessTokenSilently();
    superagent
      .post(urls.API.PRODUCTS.UPLOAD_PRODUCT_STOCKS)
      .set("Authorization", `Bearer ${token}`)
      .send(formData)
      .then(res => {
        const data = res.body;
        if (data["fail_count"] > 0) {
          pageDispatch({
            type: "UPLOAD_FAILED",
            payload: { systemFault: false, data }
          });
        } else {
          pageDispatch({ type: "UPLOAD_SUCCESSFUL", payload: data });
        }
      })
      .catch(err => {
        pageDispatch({ type: "UPLOAD_FAILED", payload: { systemFault: true } });
        console.log(err);
      });
  };

  const renderSearchSection = () => {
    const { searchQuery, searchResults } = pageState;

    return (
      <Flex mt={3} direction="column">
        <Heading>Search Stok</Heading>
        <Text>Menampilkan maksimum 8 hasil</Text>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            type="text"
            placeholder="Deskripsi produk"
            onChange={handleChange}
            value={searchQuery}
          />
        </InputGroup>
        {searchResults.length == 0 ? "Belum ada hasil search" : ""}
        <UnorderedList spacing={3} mt={2}>
          {searchResults.map((row, idx) => {
            return (
              <ListItem key={"srchRes" + idx}>{product_to_str(row)}</ListItem>
            );
          })}
        </UnorderedList>
      </Flex>
    );
  };
  const renderUploadSection = () => {
    const {
      alertMsg,
      alertStatus,
      enableUpload,
      showAlert,
      uploadText,
      isUploading
    } = pageState;

    return (
      <Stack spacing={2}>
        <Flex direction="column">
          <Heading>Upload Stok</Heading>
          {showAlert ? (
            <Alert
              p={3}
              status={alertStatus}
              alignItems="center"
              justify="center"
              flexDirection="column"
              textAlign="center"
            >
              <AlertIcon boxSize="40px" mt={2} mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {alertStatus == "error" ? "Error" : "Success!"}
              </AlertTitle>
              {alertMsg}
            </Alert>
          ) : (
            ""
          )}
        </Flex>
        <Flex
          justify="center"
          _hover={{ cursor: "pointer" }}
          {...getRootProps({ ...dropzoneStyle })}
        >
          <Input {...getInputProps()} />
          <Text>{uploadText}</Text>
        </Flex>
        <Button
          mt={2}
          disabled={!enableUpload}
          onClick={() => handleFileUpload(acceptedFiles)}
          colorScheme="blue"
          isLoading={isUploading}
        >
          Upload
        </Button>
      </Stack>
    );
  };

  const renderPage = () => {
    return (
      <Flex direction="column" flex={1}>
        <Heading as="h1" size="2xl">
          Products
        </Heading>
        {renderSearchSection()}
        <Divider mt={5} mb={5} />
        {renderUploadSection()}
      </Flex>
    );
  };

  return renderPage();
};

export default ProductPage;
