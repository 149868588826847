/**
 * About for the public
 */

import React from "react";

const PublicAboutPage = () => {

	const renderPage = () => {
  return <div>This is the public about. Selamat datang ke Demapra about</div>;
	}

	return renderPage()
};

export default PublicAboutPage;
